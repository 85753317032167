import { useState, useEffect, useCallback } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";
import { FiMenu } from "react-icons/fi";

export default function ScreenTracker(props) {
  document.title = "Mediavelocity Screen Tracker"

    // When needed, hide the MenuBar
    props.setIsMenuBarVisible(false);

  const [bgColor, setBgColor] = useState("#00b140");
  const [cornerPin, setCornerPin] = useState(true);
  const [trackerField, setTrackerField] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(true);

  // Tracker field stuff

  const [shapeColor, setShapeColor] = useState("#000000");
const [shapeCount, setShapeCount] = useState(20);
const [shapeSize, setShapeSize] = useState(5);
const [shapeSpacing, setShapeSpacing] = useState(5);
const [shapeRotation, setShapeRotation] = useState(0); // rotation in degrees
const [shapeOpacity, setShapeOpacity] = useState(1); // opacity, 0 (transparent) to 1 (opaque)


  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  
  function TrackerField({ color, count, size, spacing }) {
    function randomShape() {
      const randomVariation = randomIntFromInterval(-spacing, spacing);
      const width = size + randomVariation;
      const height = size + randomVariation;
      const x = randomIntFromInterval(0, window.innerWidth - width);
      const y = randomIntFromInterval(0, window.innerHeight - height);
    
      // Add rotation and opacity styles
      const style = {
        transform: `rotate(${shapeRotation}deg)`,
        transformOrigin: `${x + width / 2}px ${y + height / 2}px`,
        opacity: shapeOpacity,
      };
    
      // Select a shape type randomly
      const shapeType = randomIntFromInterval(0, 3);
    
      if (shapeType === 0) {
        // Rectangle
        return <rect x={x} y={y} width={width} height={height} fill={color} style={style} />;
      } else if (shapeType === 1) {
        // Triangle
        const points = `${x},${y} ${x+width},${y} ${x},${y+height}`;
        return <polygon points={points} fill={color} style={style} />;
      } else {
        // L-shape (approximation of a Tetris block)
        return (
          <>
            <rect x={x} y={y} width={width/2} height={height} fill={color} style={style} />
            <rect x={x} y={y} width={width} height={height/2} fill={color} style={style} />
          </>
        );
      }
    }
    
    
  
    const shapes = [];
    for (let i = 0; i < count; i++) {
      shapes.push(randomShape());
    }
  
    return (
      <svg viewBox={`0 0 ${window.innerWidth} ${window.innerHeight}`}>
        {shapes}
      </svg>
    );
  }
  

  


  function ToggleFullscreen() {
    if (!fullscreen) {
      document.body.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    const isFullscreen = !fullscreen;
    setFullscreen(isFullscreen);
    props.setIsMenuBarVisible(!isFullscreen);
  }
  

const keyPress = useCallback(
  (e) => {
    if (e.key === "Escape") {
      if (fullscreen) {
        document.exitFullscreen();
      }
      setFullscreen(false);
      console.log("esc key pressed");
    }
  },
  [setFullscreen, fullscreen]
);


useEffect(() => {
  // Define event handler
  const handleFullscreenChange = () => {
    const isFullscreen = !!document.fullscreenElement;
    setFullscreen(isFullscreen);
    props.setIsMenuBarVisible(!isFullscreen);
  };

  // Add event listener
  document.addEventListener("fullscreenchange", handleFullscreenChange);

  // Clean up
  return () => {
    document.removeEventListener("fullscreenchange", handleFullscreenChange);
    props.setIsMenuBarVisible(true);
  };
}, []);




  return (
    <div
      style={{
        backgroundColor: `${bgColor}`,
        minHeight: "100vh",
        position: "absolute",
        minWidth: "100vw",
        display: "fullscreen",
      }}
      className="d-flex flex-column align-items-center justify-content-center"
      // onClick={() => setMenuVisible(!menuVisible)}
    >
      {/* ------------ SHAPE FIELD ------- */}
      {trackerField && (
  <div
    style={{ minHeight: "100vh", minWidth: "100vw", overflow: "hidden" }}
    className="position-absolute overflow-hidden"
  >
<TrackerField 
  color={shapeColor}
  count={shapeCount}
  size={shapeSize}
  spacing={shapeSpacing}
  rotation={shapeRotation}
  opacity={shapeOpacity}
/>

  </div>
)}


      {cornerPin && <CornerPins />}

      {/* ------------MENU-----------*/}
      {menuVisible ? (
        <div
          className="bg-dark text-white rounded-3 p-4 position-relative"
          style={{ zIndex: "100" }}
        >
          <div
            className="position-absolute top-0"
            style={{ left: "6px" }}
            onClick={() => setMenuVisible(!menuVisible)}
          >
            <FiMenu size={20} />
          </div>
          <h2 className="fs-4 mt-2 mb-4 text-center">
            Screen Key & Tracker Points
          </h2>

          <Row className="d-flex justify-content-center">
            <Col sm={3} className="">
              <Form>
                {/* <Form.Label htmlFor="exampleColorInput">Color picker</Form.Label> */}
                <Form.Control
                  type="color"
                  id="ColorInput"
                  value={bgColor}
                  title="Choose your color"
                  onChange={(e) => setBgColor(e.target.value)}
                />
              </Form>
            </Col>
            <Col
              sm={4}
              className="d-flex justify-items-start align-items-center"
            >
             Background Color: {bgColor}
            </Col>
          </Row>

          <Button
            className="w-100 my-3 btn-sm"
            onClick={() => setCornerPin(!cornerPin)}
          >
            Corner Pin Toggle
            {cornerPin && <> OFF</>}
            {!cornerPin && <> ON</>}
          </Button>

          {/* <Button
            className="w-100 mb-3 btn-sm"
            onClick={(e) => setTrackerField(!trackerField)}
          >
            Tracking Shape Field
          </Button>

{trackerField && <>

          <Form.Control
  type="color"
  id="shapeColorInput"
  value={shapeColor}
  title="Choose your shape color"
  onChange={(e) => setShapeColor(e.target.value)}
/>

<Form.Label>Shape Count</Form.Label>
<Form.Control
  type="range"
  min={10}
  max={200}
  value={shapeCount}
  onChange={(e) => setShapeCount(e.target.value)}
/>

<Form.Label>Shape Size</Form.Label>
<Form.Control
  type="range"
  min={1}
  max={20}
  value={shapeSize}
  onChange={(e) => setShapeSize(e.target.value)}
/>

<Form.Label>Shape Rotation</Form.Label>
<Form.Control
  type="range"
  min={0}
  max={360}
  value={shapeRotation}
  onChange={(e) => setShapeRotation(e.target.value)}
/>

<Form.Label>Shape Opacity</Form.Label>
<Form.Control
  type="range"
  min={0}
  max={1}
  step={0.1}
  value={shapeOpacity}
  onChange={(e) => setShapeOpacity(e.target.value)}
/>


<Form.Label>Shape Spacing</Form.Label>
<Form.Control
  type="range"
  min={0}
  max={10}
  value={shapeSpacing}
  onChange={(e) => setShapeSpacing(e.target.value)}
/>
  </>} */}



          {fullscreen ? (
            <Button className="w-100 btn-sm mb-3" onClick={() => ToggleFullscreen()}>
              Escape Full Screen
            </Button>
          ) : (
            <Button className="w-100 btn-sm mb-3" onClick={() => ToggleFullscreen()}>
              Go Full Screen
            </Button>
          )}

          {/* {fullscreen ? <>full</>:<>no full</>} */}

          <Button className="w-100 btn-sm" onClick={() => setMenuVisible(!menuVisible)}>
  Hide Menu
</Button>


        </div>
      ) : (
        <div
          style={{
            // transform: "rotate(45deg)",
            cursor: "pointer",
            color: "white",
            opacity: "0.4",
            zIndex: "5",
          }}
          className="stretched-link"
          onClick={() => setMenuVisible(!menuVisible)}
        >
          <FiMenu size={30} />
        </div>
      )}
    </div>
  );
}

function CornerPin() {
  return (
    <svg viewBox="0 0 100 100">
      <path fill="#231f20" d="M0 0h100v100H0z" />
      <path fill="#fff" d="M35.1 35.1h29.8v29.8H35.1z" />
    </svg>
  );
}

function CornerPins() {
  return (
    <div className="position-absolute w-100 h-100">
      <div
        style={{
          width: "30px",
          position: "absolute",
          top: "25px",
          left: "25px",
        }}
      >
        <CornerPin />
      </div>
      <div
        style={{
          width: "30px",
          position: "absolute",
          top: "25px",
          right: "25px",
        }}
      >
        <CornerPin />
      </div>
      <div
        style={{
          width: "30px",
          position: "absolute",
          bottom: "25px",
          left: "25px",
        }}
      >
        <CornerPin />
      </div>
      <div
        style={{
          width: "30px",
          position: "absolute",
          bottom: "25px",
          right: "25px",
        }}
      >
        <CornerPin />
      </div>
    </div>
  );
}
